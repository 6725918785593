// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
export const ActivityLogTableData = createSlice({
  name: "ActivityLogTableData",
  initialState: {
    activityLogFiltersDDL: {},
    activityLoader: false,
    data: [],
    total: 1,
    params: {},
    activityLogData: [],
    activitySummaryData: [],
    activityCalendarData: [],
    activityLogAlreadyRequestSent: false,
    dataCounts: {},
    activityLogLoadMoreStatus: false,
    activitySummaryLoadingStatus: false,
    activitySummaryDataCounts: {},
    activitysummaryLoadingStatusLoadmore: false,
    handleActivitySummaryAlreadyRequestSent: false
  },
  reducers: {
    activityLogData: (state, action) => {
      state.activityLogData = action.payload
    },
    activitySummaryData: (state, action) => {
      state.activitySummaryData = action.payload
    },
    activityLogFilters: (state, action) => {
      state.activityLogFiltersDDL = action.payload
    },
    loadingStatus: (state, action) => {
      state.activityLoader = action.payload
    },
    dataCounts: (state, action) => {
      state.dataCounts = action.payload
    },
    loadingStatusLoadmore: (state, action) => {
      state.activityLogLoadMoreStatus = action.payload
    },
    handleActivityLogAlreadyRequestSent: (state, action) => {
      state.activityLogAlreadyRequestSent = action.payload
    },
    activitySummaryLoadingStatus: (state, action) => {
      state.activitySummaryLoadingStatus = action.payload
    },
    activitySummaryDataCounts: (state, action) => {
      state.activitySummaryDataCounts = action.payload
    },
    activitysummaryLoadingStatusLoadmore: (state, action) => {
      state.activitysummaryLoadingStatusLoadmore = action.payload
    },
    handleActivitySummaryAlreadyRequestSent: (state, action) => {
      state.handleActivitySummaryAlreadyRequestSent = action.payload
    }
  }
})
export const {
  activityLogData,
  activityLogFilters,
  activitySummaryData,
  dataCounts,
  loadingStatus,
  loadingStatusLoadmore,
  handleActivityLogAlreadyRequestSent,
  handleActivitySummaryAlreadyRequestSent,
  activitysummaryLoadingStatusLoadmore,
  activitySummaryLoadingStatus,
  activitySummaryDataCounts
} = ActivityLogTableData.actions

export const getActivitySummaryData = createAsyncThunk(
  "ActivityLogTableData/getActivitySummaryData",
  async (params, { dispatch, getState }) => {
    dispatch(handleActivitySummaryAlreadyRequestSent(true))
    const previousData = getState()?.ActivityLogTableData?.activitySummaryData
    params.Page === 1
      ? dispatch(activitySummaryLoadingStatus(true))
      : dispatch(activitysummaryLoadingStatusLoadmore(true))
    const response = await httpService(
      "GET",
      `/ActivityLog/activitysummarylist`,
      "",
      params
    )
    if (response.status === 200) {
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }
      response?.data?.pageNumber === 1
        ? dispatch(activitySummaryData(response?.data?.data))
        : dispatch(
            activitySummaryData([...previousData, ...response?.data?.data])
          )
      dispatch(activitySummaryDataCounts(pageCount))
      dispatch(activitySummaryLoadingStatus(false))
      dispatch(handleActivitySummaryAlreadyRequestSent(false))
      dispatch(activitysummaryLoadingStatusLoadmore(true))
      return true
    } else {
      dispatch(activitySummaryData([]))
      dispatch(activitySummaryDataCounts({}))
      dispatch(activitySummaryLoadingStatus(false))
      dispatch(handleActivitySummaryAlreadyRequestSent(false))
      dispatch(activitysummaryLoadingStatusLoadmore(true))
      return false
    }
  }
)
export const getActivityLogFilters = createAsyncThunk(
  "ActivityLogTableData/activityLogData",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/ActivityLog/getfilters",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(activityLogFilters(response))
    } else {
      dispatch(activityLogFilters({}))
    }
  }
)
export const getActivityLogData = createAsyncThunk(
  "ActivityLogTableData/activityLogData",
  async (params, { dispatch, getState }) => {
    dispatch(handleActivityLogAlreadyRequestSent(true))
    const previousData = getState()?.ActivityLogTableData?.activityLogData
    params.page === 1
      ? dispatch(loadingStatus(true))
      : dispatch(loadingStatusLoadmore(true))
    const response = await httpService(
      "GET",
      `/ActivityLog/activityloglist`,
      "",
      params
    )
    let pageCount
    if (response.status === 200) {
      pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }

      response?.data?.pageNumber === 1
        ? dispatch(activityLogData(response?.data?.data))
        : dispatch(activityLogData([...previousData, ...response?.data?.data]))
      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(handleActivityLogAlreadyRequestSent(false))
      dispatch(loadingStatusLoadmore(false))
      return true
    } else {
      dispatch(activityLogData([]))
      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(handleActivityLogAlreadyRequestSent(false))
      dispatch(loadingStatusLoadmore(false))
      return false
    }
  }
)
export default ActivityLogTableData.reducer
