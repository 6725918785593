import { fetchWrapper } from "@services/request"
// import { fetchBatchWrapper } from "services/batchRequest"

export const httpService = (method, url, data, params) => {
  return fetchWrapper(method, `${url}`, data, params).then(res => res)
}

// export const httpBatchService = (url, companyId) => {
//   return fetchBatchWrapper(url, companyId).then(res => res)
// }
