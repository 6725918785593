// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Dependencies Imports
import { httpService } from "@services/api"
// custom Toaster
import Toaster from "@modules/Toaster"
export const accountsettings = createSlice({
  name: "accountsettings",
  initialState: {
    loading: false,
    addRoleRightsLoading: false,
    planDetails: [],
    getCountryDDLs: [],
    getEditProfilesData: {},
    cloudIntegrations: {},
    getSettings: [],
    recentSessions: [],
    settingLoader: false,
    roleAllScreens: [],
    allRoleWithUserList: [],
    roleRightsByRoleName: {},
    roleRightsByRoleNameLoading: false,
    getBillingHistory: [],
    invoicePreview: {},
    settingLoader: false,
    roleAllScreensLoading: false,
    getProfileLoader: false,
    addCloudIntegrationLoader: false,
    editProfileLoader: false,
    isUpdateProfile: false,
    productiveLoadingStatus: false,
    productiveData: [],
    singleProductiveGroupData: [],
    productiveListLoadingStatus: false,
    productiveListData: [],
    productiveGroupEditStatus: false,
    productiveGroupEditLoader: false,
    sessionAlreadyRequestSent: false,
    sessionLoader: false,
    sessionLoadmore: false,
    dataCounts: {},
    postGroupLoader: false,
    getAvalibleCards: [],
    cardsLoading: false,
    markPayment: []
  },
  reducers: {
    postGroupLoader: (state, action) => {
      state.postGroupLoader = action.payload
    },
    dataCounts: (state, action) => {
      state.dataCounts = action.payload
    },
    sessionLoadmore: (state, action) => {
      state.sessionLoadmore = action.payload
    },
    sessionLoader: (state, action) => {
      state.sessionLoader = action.payload
    },
    sessionAlreadyRequestSent: (state, action) => {
      state.sessionAlreadyRequestSent = action.payload
    },
    productiveListData: (state, action) => {
      state.productiveListData = action.payload
    },
    productiveListLoadingStatus: (state, action) => {
      state.productiveListLoadingStatus = action.payload
    },
    productiveGroupEditStatus: (state, action) => {
      state.productiveGroupEditStatus = action.payload
    },
    productiveGroupEditLoader: (state, action) => {
      state.productiveGroupEditLoader = action.payload
    },
    productiveData: (state, action) => {
      state.productiveData = action.payload
    },
    singleProductiveGroupData: (state, action) => {
      state.singleProductiveGroupData = action.payload
    },
    productiveLoadingStatus: (state, action) => {
      state.productiveLoadingStatus = action.payload
    },
    isUpdateProfile: (state, action) => {
      state.isUpdateProfile = action.payload
    },
    editProfileLoaderHandler: (state, action) => {
      state.editProfileLoader = action.payload
    },
    getProfileLoader: (state, action) => {
      state.getProfileLoader = action.payload
    },
    loadingStatus: (state, action) => {
      state.loading = action.payload
    },
    invoicePreview: (state, action) => {
      state.invoicePreview = action.payload
    },
    getBillingHistory: (state, action) => {
      state.getBillingHistory = action.payload
    },
    getCountryDDLs: (state, action) => {
      state.getCountryDDLs = action.payload
    },
    getEditProfilesData: (state, action) => {
      state.getEditProfilesData = action.payload
    },
    getCloudIntegration: (state, action) => {
      state.cloudIntegrations = action.payload
    },
    getSettings: (state, action) => {
      state.getSettings = action.payload
    },
    settingLoadingStatus: (state, action) => {
      state.settingLoader = action.payload
    },
    addCloudIntegrationLoader: (state, action) => {
      state.addCloudIntegrationLoader = action.payload
    },
    getRecentSessions: (state, action) => {
      state.recentSessions = action.payload
    },
    getPlanDetail: (state, action) => {
      state.planDetails = action.payload
    },
    getAllScreen: (state, action) => {
      state.roleAllScreens = action.payload
    },
    getAllScreenLoading: (state, action) => {
      state.roleAllScreensLoading = action.payload
    },
    getAllRoleWithUserList: (state, action) => {
      state.allRoleWithUserList = action.payload
    },
    getAllRoleWithUserList: (state, action) => {
      state.allRoleWithUserList = action.payload
    },
    handleAddRoleRightsLoading: (state, action) => {
      state.addRoleRightsLoading = action.payload
    },
    handleGetRoleRightsByRoleNameLoading: (state, action) => {
      state.roleRightsByRoleNameLoading = action.payload
    },
    handleGetRoleRightsByRoleName: (state, action) => {
      state.roleRightsByRoleName = action.payload
    },
    getAvaliableCards: (state, action) => {
      state.getAvalibleCards = action.payload
    },
    setcardsLoading: (state, action) => {
      state.cardsLoading = action.payload
    },
    setmarkPayment: (state, action) => {
      state.markPayment = action.payload
    }
  }
})
export const {
  productiveLoadingStatus,
  postGroupLoader,
  sessionLoadmore,
  sessionLoader,
  dataCounts,
  productiveListData,
  sessionAlreadyRequestSent,
  productiveListLoadingStatus,
  productiveGroupEditStatus,
  productiveGroupEditLoader,
  singleProductiveGroupData,
  productiveData,
  getProfileLoader,
  isUpdateProfile,
  editProfileLoaderHandler,
  getAllScreenLoading,
  addCloudIntegrationLoader,
  invoicePreview,
  handleGetRoleRightsByRoleNameLoading,
  handleGetRoleRightsByRoleName,
  getAllRoleWithUserList,
  handleAddRoleRightsLoading,
  loadingStatus,
  getBillingHistory,
  getRecentSessions,
  getCountryDDLs,
  getEditProfilesData,
  getPlanDetail,
  getSettings,
  settingLoadingStatus,
  getAllScreen,
  getCloudIntegration,
  getAvaliableCards,
  setcardsLoading,
  setmarkPayment
} = accountsettings.actions

export const getCountryDDL = createAsyncThunk(
  "profile/getCountryDDL",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "Users/getcountrieslist",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getCountryDDLs(response?.data))
    } else {
      dispatch(getCountryDDLs([]))
    }
  }
)

export const getPlanDetails = createAsyncThunk(
  "planDetail/getPlanDetail",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "OrgSetting/GetCurrentSubscriptionPlan",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getPlanDetail(response?.data))
    } else {
      dispatch(getPlanDetail([]))
    }
  }
)
export const getPoliciesWithUsersList = createAsyncThunk(
  "planDetail/PoliciesWithUsersList",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/UserRights/PoliciesWithUsersList",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getAllRoleWithUserList(response?.data?.data))
    } else {
      dispatch(getAllRoleWithUserList([]))
    }
  }
)

export const GetAllScreens = createAsyncThunk(
  "planDetail/getPlanDetail",
  async (params, { dispatch }) => {
    dispatch(getAllScreenLoading(true))
    const response = await httpService(
      "GET",
      "/UserRights/GetScreens",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getAllScreen(response?.data?.data))
      dispatch(getAllScreenLoading(false))
    } else {
      dispatch(getAllScreen([]))
      dispatch(getAllScreenLoading(false))
    }
  }
)

export const addRoleRights = createAsyncThunk(
  "/UserRights/AddPolicyRights",
  async (body, { dispatch, getState }) => {
    dispatch(handleAddRoleRightsLoading(true))
    const previousData = getState().AccountSettings.allRoleWithUserList
    const previousDataCopy = [...previousData]
    const response = await httpService(
      "POST",
      "/UserRights/AddPolicyRights",
      body,
      ""
    )
    if (response.status === 200) {
      previousDataCopy.push(response.data.data)
      dispatch(getAllRoleWithUserList(previousDataCopy))
      dispatch(handleAddRoleRightsLoading(false))
      Toaster("success", response.data.message, "Success")

      return true
    } else {
      dispatch(handleAddRoleRightsLoading(false))
      Toaster("error", response.data.response.data.message, "Failed")
      return false
    }
  }
)

export const updateRoleRights = createAsyncThunk(
  "/UserRights/UpdatePolicyRights",
  async (body, { dispatch, getState }) => {
    dispatch(handleAddRoleRightsLoading(true))
    const previousData = getState().AccountSettings.allRoleWithUserList
    const previousDataCopy = [...previousData]
    const index = previousData.findIndex(
      item => item.description === body.policyName
    )

    const response = await httpService(
      "POST",
      "/UserRights/UpdatePolicyRights",
      body,
      ""
    )
    if (response.status === 200) {
      previousDataCopy.splice(index, 1, response.data.data)
      dispatch(getAllRoleWithUserList(previousDataCopy))
      dispatch(handleAddRoleRightsLoading(false))
      Toaster("success", response.data.message, "Success")

      return true
    } else {
      dispatch(handleAddRoleRightsLoading(false))
      Toaster("error", response.data.response.data.message, "Failed")
      return false
    }
  }
)

export const getRoleRightsByRoleName = createAsyncThunk(
  "/UserRights/AddPolicyRights",
  async (params, { dispatch }) => {
    dispatch(handleGetRoleRightsByRoleNameLoading(true))
    const response = await httpService(
      "GET",
      "/UserRights/GetPolicyRights",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(handleGetRoleRightsByRoleName(response?.data))
      dispatch(handleGetRoleRightsByRoleNameLoading(false))

      return true
    } else {
      dispatch(handleGetRoleRightsByRoleNameLoading(false))
      dispatch(handleGetRoleRightsByRoleName([]))

      return false
    }
  }
)

export const deletePolicyByPoliCyId = createAsyncThunk(
  "/UserRights/RemovePolicy",
  async (params, { dispatch, getState }) => {
    const previousData = getState().AccountSettings.allRoleWithUserList
    const previousDataCopy = [...previousData]
    const index = previousData.findIndex(
      item => item.policyId === params.policyId
    )
    previousDataCopy.splice(index, 1)
    const response = await httpService(
      "DELETE",
      "/UserRights/RemovePolicy",
      "",
      params
    )
    if (response?.status === 200) {
      if (index > -1) {
        dispatch(getAllRoleWithUserList(previousDataCopy))
      }
      Toaster("success", "Role Deleted Successfully", "Success")
    } else {
      Toaster(
        "error",
        response?.data?.response?.data?.message
          ? response?.data?.response?.data?.message
          : "Something went wrong please try again.",
        "Failed"
      )
    }
    return response?.status === 200 ? true : false
  }
)

export const postEditProfile = createAsyncThunk(
  "profile/postEditProfile",
  async (params, { dispatch }) => {
    dispatch(editProfileLoaderHandler(true))
    const response = await httpService("POST", "Users/editprofile", params, "")
    if (response.status === 200) {
      Toaster("success", "Profile Updated Successfully", "Success")
      dispatch(editProfileLoaderHandler(false))
      dispatch(isUpdateProfile(Math.random().toFixed(2)))
      return true
    } else {
      Toaster("error", "Something went wrong please try again.", "Failed")
      dispatch(editProfileLoaderHandler(false))
      return false
    }
  }
)

export const getCloudIntegrations = createAsyncThunk(
  "integrations/cloudIntegrations",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "OrgSetting/addcloudintegration",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getCloudIntegration(response?.data))
    } else {
      dispatch(getCloudIntegration({}))
    }
    return response
  }
)

export const postCloudIntegration = createAsyncThunk(
  "profile/postCloudIntegration",
  async (params, { dispatch, getState }) => {
    dispatch(addCloudIntegrationLoader(true))
    const previousState = getState()?.AccountSettings?.cloudIntegrations
    const integrationDDL = [...previousState?.integrationDDL]
    const updatedObj = {
      ...integrationDDL?.[params?.switchbuttonsKey],
      value: true
    }
    integrationDDL[params?.switchbuttonsKey] = updatedObj
    const response = await httpService(
      "POST",
      "OrgSetting/addCloudIntegration",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(getCloudIntegrations())
      dispatch(addCloudIntegrationLoader(false))
      Toaster("success", "Cloud Settings Changed Successfully", "Success")
    } else {
      dispatch(addCloudIntegrationLoader(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response.status === 200 ? true : false
  }
)
export const getEditProfileData = createAsyncThunk(
  "profile/getCountryDDL",
  async (params, { dispatch }) => {
    dispatch(getProfileLoader(true))
    const response = await httpService("GET", "Users/myprofile", params, "")
    if (response.status === 200) {
      dispatch(getProfileLoader(false))
      dispatch(getEditProfilesData(response?.data?.data))
    } else {
      dispatch(getProfileLoader(false))
      dispatch(getEditProfilesData({}))
    }
  }
)

export const getChangePassword = createAsyncThunk(
  "AccountSettings/changePassword",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "POST",
      "/Users/resetpassword",
      params,
      ""
    )
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      return { success: true, message: "password change successfully" }
    } else {
      dispatch(loadingStatus(false))
      return {
        success: false,
        message: response?.data?.response?.data?.message
      }
    }
  }
)
export const getProductiveData = createAsyncThunk(
  "datatables/getProductiveData",
  async (param, { dispatch }) => {
    dispatch(productiveLoadingStatus(true))
    const response = await httpService(
      "GET",
      "AppsWebsitesLabels/GetOrgGroups",
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(productiveData(response?.data))
      dispatch(productiveLoadingStatus(false))
    } else {
      dispatch(productiveData([]))
      dispatch(productiveLoadingStatus(false))
    }
  }
)
export const editProductiveGroupData = createAsyncThunk(
  "datatables/editProductiveGroupData",
  async (param, { dispatch }) => {
    dispatch(productiveGroupEditStatus(true))
    dispatch(productiveGroupEditLoader(true))
    const response = await httpService(
      "GET",
      "/AppsWebsitesLabels/EditOrgGroup",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(singleProductiveGroupData(response?.data))
      dispatch(productiveGroupEditLoader(false))
    } else {
      dispatch(singleProductiveGroupData([]))
      dispatch(productiveGroupEditLoader(false))
    }
  }
)
export const updateGroup = createAsyncThunk(
  "datatables/updateGroup",
  async (param, { dispatch, getState }) => {
    dispatch(postGroupLoader(true))
    const response = await httpService(
      "POST",
      "/AppsWebsitesLabels/EditOrgGroup",
      param,
      ""
    )
    if (response.status === 200) {
      const previousGroups = getState()?.AccountSettings?.productiveData
      const index = previousGroups.findIndex(
        group => group.groupId === response?.data[0].groupId
      )
      if (index !== -1) {
        const updatedGroups = [
          ...previousGroups.slice(0, index),
          response?.data[0],
          ...previousGroups.slice(index + 1)
        ]
        dispatch(productiveData(updatedGroups))
      }
      Toaster("success", "Group updated Successfully", "Success")
      dispatch(postGroupLoader(false))
      return true
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
      dispatch(postGroupLoader(false))
      return false
    }
  }
)
export const getProductiveListData = createAsyncThunk(
  "datatables/getProductiveListData",
  async (param, { dispatch }) => {
    dispatch(productiveListLoadingStatus(true))
    const response = await httpService(
      "GET",
      "AppsWebsitesLabels/GetOrgGroupApps",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(productiveListData(response?.data))
      dispatch(productiveListLoadingStatus(false))
      return true
    } else {
      dispatch(productiveListData([]))
      dispatch(productiveListLoadingStatus(false))
      return false
    }
  }
)
export const billingHistory = createAsyncThunk(
  "datatables/billingHistory",
  async (param, { dispatch }) => {
    dispatch(settingLoadingStatus(true))
    const response = await httpService(
      "GET",
      "OrgSetting/GetOrganizationInvoices",
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(getBillingHistory(response?.data))
      dispatch(settingLoadingStatus(false))
    } else {
      dispatch(getBillingHistory([]))
      dispatch(settingLoadingStatus(false))
    }
  }
)
export const getSetting = createAsyncThunk(
  "datatables/getSetting",
  async (param, { dispatch }) => {
    dispatch(settingLoadingStatus(true))
    const response = await httpService("GET", "/OrgSetting/add", "", "")

    if (response.status === 200) {
      dispatch(getSettings(response?.data))
      dispatch(settingLoadingStatus(false))
    } else {
      dispatch(getSettings([]))
      dispatch(settingLoadingStatus(false))
    }
  }
)

export const postGroup = createAsyncThunk(
  "datatables/postGroup",
  async (param, { dispatch, getState }) => {
    dispatch(postGroupLoader(true))
    const previousGroups = getState()?.AccountSettings?.productiveData
    const response = await httpService(
      "POST",
      "AppsWebsitesLabels/AddOrgGroup",
      param,
      ""
    )
    if (response.status === 200) {
      Toaster("success", "Group added Successfully", "Success")
      dispatch(productiveData([...previousGroups, response.data[0]]))
      dispatch(postGroupLoader(false))
      return true
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
      dispatch(postGroupLoader(false))
      return false
    }
  }
)
export const getSessionData = createAsyncThunk(
  "ActivityLogTableData/activityLogData",
  async (params, { dispatch, getState }) => {
    dispatch(sessionAlreadyRequestSent(true))
    const previousData = getState()?.AccountSettings?.recentSessions
    params.Page === 1
      ? dispatch(sessionLoader(true))
      : dispatch(sessionLoadmore(true))

    const response = await httpService("GET", "Users/recentlogins", "", params)
    let pageCount = {}
    if (response.status === 200) {
      pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }
      response?.data?.pageNumber === 1
        ? dispatch(getRecentSessions(response?.data?.data))
        : dispatch(
            getRecentSessions([...previousData, ...response?.data?.data])
          )
      dispatch(dataCounts(pageCount))
      dispatch(sessionLoader(false))
      dispatch(sessionAlreadyRequestSent(false))
      dispatch(sessionLoadmore(false))
      return true
    } else {
      dispatch(getRecentSessions([]))
      dispatch(dataCounts(pageCount))
      dispatch(sessionAlreadyRequestSent(false))
      dispatch(sessionLoader(false))
      dispatch(sessionLoadmore(false))
      return false
    }
  }
)
export const postSettings = createAsyncThunk(
  "datatables/postSettings",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/OrgSetting/add", params, "")
    if (response.status === 200) {
      dispatch(loadingStatus(false))
      Toaster("success", "General Settings Edit Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
export const getInvoicePreview = createAsyncThunk(
  "datatables/getInvoicePreview",
  async (param, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      `/OrgSetting/GetInvoicePreview?InvoiceId=${param}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(invoicePreview(response?.data?.data))
    } else {
      dispatch(invoicePreview({}))
    }
  }
)
export const GetAllAvalibleCards = createAsyncThunk(
  "OrgSetting/GetAvailableCards",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      `/OrgSetting/GetAvailableCards`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(getAvaliableCards(response?.data))
    } else {
      dispatch(getAvaliableCards([]))
    }
  }
)
export const GetMarkDefaultPaymentmethod = createAsyncThunk(
  "/OrgSetting/MarkDefaultPaymentMethod",
  async (params, { getState, dispatch }) => {
    dispatch(setcardsLoading(true))
    const Cards = getState()?.AccountSettings?.getAvalibleCards
    const response = await httpService(
      "POST",
      `/OrgSetting/MarkDefaultPaymentMethod/${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(setcardsLoading(false))
      Toaster("success", response?.data, "Success")
      dispatch(getAvaliableCards({ ...Cards, defaultId: params }))
    }
  }
)

export const MarkPaymentsStripe = createAsyncThunk(
  "/OrgSetting/MarkPayment",
  async param => {
    const response = await httpService(
      "POST",
      `/OrgSetting/MarkPayment`,
      "",
      param
    )
    if (response.status === 200) {
      Toaster("success", "General Settings Edit Successfully", "Success")
    } else {
      Toaster("error", "General Settings Edit Successfully", "Error")
    }
  }
)
export default accountsettings.reducer
