// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
export const mainModalsSlice = createSlice({
  name: "mainModals",
  initialState: {
    isTaskUpdated: false,
    openTaskSidebar: false,
    openDetailSidebar: false,
    projectIsOpen: false,
    taskGroupIsOpen: false,
    selectedProjectId: null,
    selectedPriority: null,
    selectedStatus: null,
    selectedTaskType: null,
    selectedUserId: null,
    projectIDFromAddTask: null,
    addTaskCondition: null,
    subtasksIsOpen: false,
    duestatus: "",
    tagModalIsOpen: false
  },
  reducers: {
    handleTaskSidebar: (state, action) => {
      state.openTaskSidebar = action.payload
    },
    setProjectIDFromAddTask: (state, action) => {
      state.projectIDFromAddTask = action.payload
    },
    setAddTaskCondition: (state, action) => {
      state.addTaskCondition = action.payload
    },
    handlePriority: (state, action) => {
      state.selectedPriority = action.payload
    },
    handleStatus: (state, action) => {
      state.selectedStatus = action.payload
    },
    handleDueStatus: (state, action) => {
      state.duestatus = action.payload
    },
    handleTaskType: (state, action) => {
      state.selectedTaskType = action.payload
    },
    handleDetailSidebar: (state, action) => {
      state.openDetailSidebar = action.payload
    },
    handleMyTaskGroupModal: (state, action) => {
      state.taskGroupIsOpen = action.payload
    },
    handleTagModal: (state, action) => {
      state.tagModalIsOpen = action.payload
    },
    handleMyTaskProjectModal: (state, action) => {
      state.projectIsOpen = action.payload
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload
    },
    setIsTaskUpdated: (state, action) => {
      state.isTaskUpdated = action.payload
    },
    handleSubtaskModal: (state, action) => {
      state.subtasksIsOpen = action.payload
    }
  }
})
export const {
  setAddTaskCondition,
  setProjectIDFromAddTask,
  setIsTaskUpdated,
  handleTaskSidebar,
  handlePriority,
  handleStatus,
  handleDueStatus,
  handleTaskType,
  handleDetailSidebar,
  handleMyTaskGroupModal,
  handleTagModal,
  handleMyTaskProjectModal,
  setSelectedProjectId,
  setSelectedUserId,
  handleSubtaskModal,
  handleSelectedTask
} = mainModalsSlice.actions
export default mainModalsSlice.reducer
