// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import filter from "./filter"
import taskView from "./taskView"
import auth from "./authentication"
import FAQ from "@src/views/admin/FAQ/Store"
import Chat from "@src/views/user/Chat/store"
import LoginPage from "@src/views/pages/store"
import Dashboard from "@src/views/user/Home/Store"
import myTasks from "@src/views/pages/MyTasks/store"
import AdminDashboard from "@src/views/admin/Home/Store"
import TagsData from "@src/views/admin/Define/Tags/Store"
import TeamData from "@src/views/admin/Define/Team/Store"
import myProjects from "@src/views/admin/MyProjects/store"
import screenshots from "@src/views/admin/Screenshots/store"
import realTimedataTables from "@src/views/admin/RealTime/store"
import TaskGroup from "@src/views/admin/Define/TasksGroup/Store"
import MyAttendanceData from "@src/views/user/MyAttendance/Store"
import EmployeeData from "@src/views/admin/Define/Employees/Store"
import customTemplates from "@src/views/admin/Define/Templates/Store"
import reportMyTeam from "@src/views/admin/Analytics/MyTeam/store"
import AccountSettings from "@src/views/pages/AccountSettings/store"
import ActivityLogTableData from "@src/views/admin/AppActivity/store"
import TeamAttendanceData from "@src/views/admin/TeamAttendance/Store"
import Notifications from "../@template/layouts/components/navbar/Store"
import AnalyticsTableData from "@src/views/admin/Analytics/Attendance/store"
import MainModals from "@src/views/pages/MyTasks/components/MainModals/store"
import ActivityCalenderData from "@src/views/admin/AppActivity/ActivityCalender/Store"
import signalR from "@src/signalR/store"
import KnowledgeBaseData from "@src/views/pages/KnowledgeBase/store"
import Ticket from "@src/views/pages/Tickets/Store"
const rootReducer = {
  MainModals,
  auth,
  navbar,
  layout,
  filter,
  taskView,
  Chat,
  AccountSettings,
  myTasks,
  LoginPage,
  myProjects,
  FAQ,
  reportMyTeam,
  screenshots,
  realTimedataTables,
  AnalyticsTableData,
  ActivityLogTableData,
  EmployeeData,
  TagsData,
  ActivityCalenderData,
  TeamData,
  TeamAttendanceData,
  MyAttendanceData,
  TaskGroup,
  Notifications,
  Dashboard,
  AdminDashboard,
  signalR,
  customTemplates,
  KnowledgeBaseData,
  Ticket
}

export default rootReducer
