// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// ** Dependencies Imports
export const MyAttendanceData = createSlice({
  name: "MyAttendanceData",
  initialState: {
    myAttendanceData: [],
    isLoading: false
  },
  reducers: {
    myAttendance: (state, action) => {
      state.myAttendanceData = action.payload
    },
    loadingStatus: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

export const { myAttendance, loadingStatus } = MyAttendanceData.actions

export const getMyAttendance = createAsyncThunk(
  "MyAttendanceData/getMyAttendance",
  async (params, { dispatch }) => {
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    dispatch(loadingStatus(true))
    const response = await httpService(
      "GET",
      "MyTasks/getmyattendancesheet",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(myAttendance(response?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(myAttendance([]))
      dispatch(loadingStatus(false))
    }
  }
)

export default MyAttendanceData.reducer
