// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const signalRSlice = createSlice({
  name: "singalR",
  initialState: {
    attendance: false
  },
  reducers: {
    attendanceStart: (state, action) => {
      state.attendance = action.payload
    }
  }
})

export const { attendanceStart } = signalRSlice.actions

export default signalRSlice.reducer
