// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { handleProjectSingleDDL } from "@views/pages/MyTasks/store"
import { employeesData } from "../../Define/Employees/Store"
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const myProjectSlice = createSlice({
  name: "myProject",
  initialState: {
    projects: [],
    getProjectDDL: [],
    getProjectData: [],
    tasksStatus: [],
    getExistingEmployee: [],
    getExistingManagers: [],
    getObserverList: [],
    getEmployeesDDL: [],
    getManagersDDL: [],
    loader: false,
    projectLoadingStatus: false,
    overDueTasks: [],
    UpComingDeadlines: [],
    workload: [],
    observerLoading: false,
    isLoading: false,
    observerListLoading: false,
    managersDDLLoader: false,
    projectLoadMoreStatus: false,
    existingEmployeeListLoader: false,
    counts: {},
    activeStatus: true,
    projectFiltersDDLsLoader: false,
    existingManagerListLoader: false,
    deleteObserverLoader: false,
    employeesDDLLoader: false,
    projectFiltersDDL: [],
    filterEmployeeDDLs: [],
    alreadyRequestSent: false
  },
  reducers: {
    filterEmployeeDDL: (state, action) => {
      state.filterEmployeeDDLs = action.payload
    },
    projectFiltersDDL: (state, action) => {
      state.projectFiltersDDL = action.payload
    },
    employeesDDLLoader: (state, action) => {
      state.employeesDDLLoader = action.payload
    },
    projectFiltersDDLsLoader: (state, action) => {
      state.projectFiltersDDLsLoader = action.payload
    },
    allProjects: (state, action) => {
      state.projects = action.payload
    },
    projectsData: (state, action) => {
      state.getProjectData = action.payload
    },
    getObserverList: (state, action) => {
      state.getObserverList = action.payload
    },
    getProjectDDL: (state, action) => {
      state.getProjectDDL = action.payload
    },
    loadingProjects: (state, action) => {
      state.isLoading = action.payload
    },
    existingEmployeeListLoader: (state, action) => {
      state.existingEmployeeListLoader = action.payload
    },
    existingManagerListLoader: (state, action) => {
      state.existingManagerListLoader = action.payload
    },
    getExistingEmployee: (state, action) => {
      state.getExistingEmployee = action.payload
    },
    observerLoading: (state, action) => {
      state.observerLoading = action.payload
    },
    loader: (state, action) => {
      state.loader = action.payload
    },
    observerListLoading: (state, action) => {
      state.observerListLoading = action.payload
    },
    managersDDLLoader: (state, action) => {
      state.managersDDLLoader = action.payload
    },
    getExistingManager: (state, action) => {
      state.getExistingManagers = action.payload
    },
    getManagerDDL: (state, action) => {
      state.getManagersDDL = action.payload
    },
    getEmployeesDDL: (state, action) => {
      state.getEmployeesDDL = action.payload
    },
    dataCounts: (state, action) => {
      state.counts = action.payload
    },
    workload: (state, action) => {
      state.workload = action.payload
    },
    UpComingDeadlines: (state, action) => {
      state.UpComingDeadlines = action.payload
    },
    tasksStatus: (state, action) => {
      state.tasksStatus = action.payload
    },
    loadingStatus: (state, action) => {
      state.projectLoadingStatus = action.payload
    },
    loadingStatusLoadmore: (state, action) => {
      state.projectLoadMoreStatus = action.payload
    },
    deleteObserverLoader: (state, action) => {
      state.deleteObserverLoader = action.payload
    },
    overDueTasks: (state, action) => {
      state.overDueTasks = action.payload
    },
    handleActiveStatus: (state, action) => {
      state.activeStatus = action.payload
    },
    alreadyRequestSent: (state, action) => {
      state.alreadyRequestSent = action.payload
    }
  }
})
export const {
  allProjects,
  getProjectDDL,
  getObserverList,
  alreadyRequestSent,
  projectFiltersDDLsLoader,
  existingEmployeeListLoader,
  existingManagerListLoader,
  observerLoading,
  observerListLoading,
  deleteObserverLoader,
  getExistingEmployee,
  projectFiltersDDL,
  getExistingManager,
  loadingProjects,
  managersDDLLoader,
  getManagerDDL,
  loadingStatus,
  projectsData,
  loader,
  tasksStatus,
  workload,
  overDueTasks,
  UpComingDeadlines,
  handleActiveStatus,
  getEmployeesDDL,
  dataCounts,
  employeesDDLLoader,
  loadingStatusLoadmore,
  filterEmployeeDDL
} = myProjectSlice.actions

export const deleteProject = createAsyncThunk(
  "myProject/deletePorject",
  async (params, { dispatch, getState }) => {
    const previousData = getState().myProjects.projects
    const previousDataCopy = [...previousData]
    const fileIndex = previousData.findIndex(
      file => file.projectsVM?.projectId === params.ProjectId
    )
    previousDataCopy.splice(fileIndex, 1)
    const response = await httpService(
      "POST",
      "/Projects/RemoveProject",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(allProjects(previousDataCopy))
      Toaster("success", response?.data?.message, "Success")
    } else {
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
  }
)

export const getWorkLoad = createAsyncThunk(
  "myProject/getWorkLoad",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projecttasksworkload",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(workload(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(workload([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const projectFilterDDLs = createAsyncThunk(
  "myProject/projectFilterDDLs",
  async (params, { dispatch }) => {
    dispatch(projectFiltersDDLsLoader(true))
    const response = await httpService(
      "GET",
      "/Projects/getprojectfilters",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(projectFiltersDDL(response?.data))
      dispatch(projectFiltersDDLsLoader(false))
    } else {
      dispatch(projectFiltersDDL([]))
      dispatch(projectFiltersDDLsLoader(false))
    }
  }
)
export const filterEmployeeDDLs = createAsyncThunk(
  "myProject/projectFilterEmployeeDDLs",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/getempslist",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(filterEmployeeDDL(response?.data?.data))
    } else {
      dispatch(projectFiltersDDL([]))
    }
  }
)
export const getTasksStatus = createAsyncThunk(
  "myProject/getTasksStatus",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectdashboard",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(tasksStatus(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(tasksStatus([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getUpComingDeadlines = createAsyncThunk(
  "myProject/getUpComingDeadlines",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectupcomingtasks",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(UpComingDeadlines(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(UpComingDeadlines([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getOverDueTasks = createAsyncThunk(
  "myProject/getOverDueTasks",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamTasks/projectoverduetasks",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(overDueTasks(response?.data?.data))
      dispatch(loadingStatus(false))
    } else {
      dispatch(overDueTasks([]))
      dispatch(loadingStatus(false))
    }
  }
)
export const getProjectsDDL = createAsyncThunk(
  "projecttable/getProjectsDDL",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/Projects/addproject",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getProjectDDL(response?.data))
    }
  }
)
export const getProjects = createAsyncThunk(
  "myProject/getProjects",
  async (params, { dispatch, getState }) => {
    dispatch(alreadyRequestSent(true))
    const previousData = getState().myProjects.projects
    params.Page === 1
      ? dispatch(loadingStatus(true))
      : dispatch(loadingStatusLoadmore(true))
    const response = await httpService(
      "GET",
      "/projects/myprojectslist",
      "",
      params
    )
    if (response.status === 200) {
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }

      response?.data?.pageNumber === 1
        ? dispatch(allProjects(response?.data?.data))
        : dispatch(allProjects([...previousData, ...response?.data?.data]))

      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(loadingStatusLoadmore(false))
      dispatch(alreadyRequestSent(false))

      return true
    } else {
      dispatch(allProjects([]))
      dispatch(alreadyRequestSent(false))
      dispatch(dataCounts({}))
      dispatch(loadingStatus(false))
      dispatch(loadingStatusLoadmore(false))
      return false
    }
  }
)
export const postProject = createAsyncThunk(
  "projecttable/postProject",
  async (params, { dispatch, getState }) => {
    dispatch(loader(true))
    const { isActive } = params
    const response = await httpService(
      "POST",
      "/Projects/addproject",
      params,
      ""
    )
    if (response.status === 200) {
      const previousState = [...getState()?.myProjects?.projects]

      const addObj = {
        projectsVM: response.data,
        vProjectwiseSummary: response.data
      }

      getState()?.myProjects?.activeStatus === isActive
        ? dispatch(allProjects([addObj, ...previousState]))
        : ""

      dispatch(loader(false))
      dispatch(
        handleProjectSingleDDL({
          label: response?.data?.projectName,
          value: response?.data?.projectId
        })
      )
      Toaster("success", "Project Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      if (response?.data?.response?.data?.errors[0]) {
        Toaster("error", response?.data?.response?.data?.errors[0], "Failed")
      } else {
        Toaster("error", "Something went wrong please try again.", "Failed")
      }
    }
    return response
  }
)
export const editProject = createAsyncThunk(
  "projecttable/editProject",
  async (params, { dispatch, getState }) => {
    dispatch(loader(true))
    const { isActive } = params
    const response = await httpService(
      "POST",
      "/Projects/editproject",
      params,
      ""
    )
    if (response.status === 200) {
      const previousState = [...getState().myProjects.projects]

      const index = previousState?.findIndex(
        item => item?.projectsVM?.projectId === params?.projectId
      )
      const updatedObj = {
        projectsVM: response.data,
        vProjectwiseSummary: previousState[index].vProjectwiseSummary
      }
      getState().myProjects.activeStatus === isActive
        ? (previousState[index] = updatedObj)
        : previousState.splice(index, 1)

      dispatch(loader(false))
      dispatch(allProjects(previousState))
      Toaster("success", "Project Edit Successfully", "Success")
    } else {
      dispatch(loader(false))
      if (response?.data?.response?.data?.errors[0]) {
        Toaster("error", response?.data?.response?.data?.errors[0], "Failed")
      } else {
        Toaster("error", "Something went wrong please try again.", "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)
export const addEmployees = createAsyncThunk(
  "projecttable/addEmployees",
  async (params, { dispatch, getState }) => {
    dispatch(loader(true))
    const existingEmployees = getState()?.myProjects?.getExistingEmployee
    const { employeeList, projectId } = params
    const response = await httpService(
      "POST",
      `/TeamTasks/addprojectemps?projectId=${projectId}`,
      employeeList
    )
    if (response.status === 200) {
      dispatch(loader(false))
      const data = [...existingEmployees, ...response?.data?.data]
      dispatch(getExistingEmployee(data))
      Toaster("success", "Project Employees Added Successfully", "Success")
    } else {
      dispatch(loader(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response.status === 200 ? true : false
  }
)
export const getExistingEmployees = createAsyncThunk(
  "projecttable/getExistingEmployees",
  async (params, { dispatch }) => {
    dispatch(existingEmployeeListLoader(true))
    const response = await httpService(
      "GET",
      `/TeamTasks/getprojectemps?projectId=${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(existingEmployeeListLoader(false))
      dispatch(getExistingEmployee(response?.data?.data))
    }
  }
)
export const getEmployeeDDL = createAsyncThunk(
  "projecttable/getEmployeeDDL",
  async (params, { dispatch }) => {
    dispatch(employeesDDLLoader(true))
    const response = await httpService("GET", "TeamTasks/addprojectemps", "", {
      projectId: params
    })
    if (response.status === 200) {
      dispatch(getEmployeesDDL(response?.data?.employeesDDL))
      dispatch(employeesDDLLoader(false))
    }
  }
)

export const addProjectManager = createAsyncThunk(
  "projecttable/addProjectManager",
  async (params, { dispatch, getState }) => {
    const existingManagers = getState()?.myProjects?.getExistingManagers
    dispatch(loader(true))
    const { managersList, projectId } = params
    const response = await httpService(
      "POST",
      `TeamTasks/addprojectmanagers?projectId=${projectId}`,
      managersList,
      ""
    )
    if (response.status === 200) {
      dispatch(loader(false))
      dispatch(
        getExistingManager([...existingManagers, ...response?.data?.data])
      )
      Toaster("success", "Project Mangers Added Successfully", "Success")
    } else {
      dispatch(loader(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response.status === 200 ? true : false
  }
)
export const getManagersDDL = createAsyncThunk(
  "projecttable/getManagersDDL",
  async (params, { dispatch }) => {
    dispatch(managersDDLLoader(true))
    const response = await httpService(
      "GET",
      "/TeamTasks/addprojectmanagers",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(managersDDLLoader(false))
      dispatch(getManagerDDL(response?.data?.employeesDDL))
    }
  }
)
export const removeExistingManager = createAsyncThunk(
  "projecttable/RemoveProjectManager",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectManager",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.myProjects?.getExistingManagers?.filter(
        item => item.userId !== params.UserId
      )
      dispatch(getExistingManager(data))
      dispatch(getManagersDDL({ projectId: params?.ProjectId }))
      Toaster("success", response?.data?.message, "Success")
    } else {
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)
export const removeObserver = createAsyncThunk(
  "projecttable/removeObserver",
  async (params, { dispatch, getState }) => {
    dispatch(deleteObserverLoader(params?.ObserverId))
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectObserver",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.myProjects?.getObserverList?.filter(
        item => item?.userId !== params?.ObserverId
      )
      dispatch(getObserverList(data))
      dispatch(deleteObserverLoader(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(deleteObserverLoader(false))
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)
export const addProjectObserver = createAsyncThunk(
  "projecttable/addProjectObserver",
  async (params, { dispatch }) => {
    dispatch(observerLoading(true))
    const response = await httpService(
      "POST",
      "/Projects/AddProjectObservers",
      params,
      ""
    )
    if (response?.status === 200) {
      dispatch(observerLoading(false))
      Toaster("success", response?.data?.message, "Success")
    } else {
      dispatch(observerLoading(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const getObserverLists = createAsyncThunk(
  "projecttable/getObserverLists",
  async (params, { dispatch }) => {
    dispatch(observerListLoading(true))
    const response = await httpService(
      "GET",
      "/Projects/GetProjectObservers",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(getObserverList(response?.data?.data))
      dispatch(observerListLoading(false))
    } else {
      dispatch(observerListLoading(false))
    }
  }
)
export const getExistingManagers = createAsyncThunk(
  "projecttable/getExistingManagers",
  async (params, { dispatch }) => {
    dispatch(existingManagerListLoader(true))
    const response = await httpService(
      "GET",
      `/TeamTasks/getprojectmanagers?projectId=${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(existingManagerListLoader(false))
      dispatch(getExistingManager(response?.data?.data))
    }
  }
)
//ANOTHER
export const getProjectData = createAsyncThunk(
  "projecttable/getData",
  async (params, { dispatch }) => {
    dispatch(loadingProjects(true))
    params = { PageSize: 1000, page: 1 }
    const response = await httpService(
      "GET",
      "Projects/allprojectslist",
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(projectsData(response?.data?.data))
      dispatch(loadingProjects(false))
    } else {
      dispatch(projectsData([]))
      dispatch(loadingProjects(false))
    }
  }
)

export const removeExistingEmployee = createAsyncThunk(
  "projecttable/removeExistingEmployee",
  async (params, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "/Projects/RemoveProjectEmployee",
      "",
      params
    )
    if (response?.status === 200) {
      const data = getState()?.myProjects?.getExistingEmployee?.filter(
        item => item?.userId !== params?.UserId
      )
      dispatch(getExistingEmployee(data))
      dispatch(getEmployeeDDL(params?.ProjectId))
      Toaster("success", response?.data?.message, "Success")
    } else {
      if (response?.data?.response?.data?.message) {
        Toaster("error", response?.data?.response?.data?.message, "Failed")
      } else {
        Toaster("error", response?.data?.message, "Failed")
      }
    }
    return response?.status === 200 ? true : false
  }
)
export const removeExistingEmployeeAndResignTasks = createAsyncThunk(
  "projecttable/removeExistingEmployeeAndResignTasks",
  async (params, { dispatch, getState }) => {
    const { ProjectId, ...resignTasksParams } = params
    const resignTasksResponse = await httpService(
      "POST",
      `/Employees/reassignusertasks`,
      resignTasksParams,
      ""
    )
    // eslint-disable-next-line
    const { projectTasks, ...removeUserParams } = params
    if (resignTasksResponse.status === 200) {
      const RemoveUserResponse = await httpService(
        "POST",
        "/Projects/RemoveProjectEmployee",
        "",
        removeUserParams
      )
      if (RemoveUserResponse?.status === 200) {
        const { employeeData } = getState()?.EmployeeData
        const updatedData = employeeData?.filter(
          item => item?.userId !== params?.userId
        )
        dispatch(employeesData(updatedData))
        const data = getState()?.myProjects?.getExistingEmployee?.filter(
          item => item?.userId !== params?.userId
        )
        dispatch(getExistingEmployee(data))
        Toaster("success", RemoveUserResponse?.data?.message, "Success")
      } else {
        if (RemoveUserResponse?.data?.response?.data?.message) {
          Toaster(
            "error",
            RemoveUserResponse?.data?.response?.data?.message,
            "Failed"
          )
        } else {
          Toaster("error", RemoveUserResponse?.data?.message, "Failed")
        }
      }
    } else {
      Toaster("error", resignTasksResponse?.data?.message, "Failed")
    }
  }
)
export default myProjectSlice.reducer
