// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import moment from "moment"
export const dashboardSlice = createSlice({
  name: "adminDashboard",
  initialState: {
    adminTopProjectData: [],
    adminTopTaskData: [],
    adminTasksAllCount: {},
    countLoading: false,
    topTaskLoading: false,
    topProjectLoading: false,
    topEmployees: [],
    topEmployeesLoader: false,
    bilingAnalytics: [],
    billingLoading: false,
    topteams: [],
    topteamsLoading: false,
    productivity: [],
    productivityLoading: false,
    TaskCount: 0,
    dateRange: []
  },
  reducers: {
    getTopProject: (state, action) => {
      state.adminTopProjectData = action.payload
    },
    getTopTask: (state, action) => {
      state.adminTopTaskData = action.payload
    },
    setAllTaskCount: (state, action) => {
      state.adminTasksAllCount = action.payload
    },
    getCountLoading: (state, action) => {
      state.countLoading = action.payload
    },
    getCountLoading: (state, action) => {
      state.countLoading = action.payload
    },
    getCountLoading: (state, action) => {
      state.countLoading = action.payload
    },
    getTopTaskLoading: (state, action) => {
      state.topTaskLoading = action.payload
    },
    getTopProjectLoading: (state, action) => {
      state.topProjectLoading = action.payload
    },
    setTopEmployees: (state, action) => {
      state.topEmployees = action.payload
    },
    updateTopEmployeesLoader: (state, action) => {
      state.topEmployeesLoader = action.payload
    },
    getTopBillings: (state, action) => {
      state.bilingAnalytics = action.payload
    },
    getTopBillingLoading: (state, action) => {
      state.billingLoading = action.payload
    },
    setTopTeams: (state, action) => {
      state.topteams = action.payload
    },
    updateTopTeams: (state, action) => {
      state.topteamsLoading = action.payload
    },
    setProductivity: (state, action) => {
      state.productivity = action.payload
    },
    updateProductivityLoading: (state, action) => {
      state.productivityLoading = action.payload
    },
    UpdateTaskCount: (state, action) => {
      state.adminTasksAllCount = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    }
  }
})
export const {
  getTopProject,
  getTopTask,
  setAllTaskCount,
  getCountLoading,
  getTopTaskLoading,
  getTopProjectLoading,
  setTopEmployees,
  updateTopEmployeesLoader,
  getTopBillings,
  getTopBillingLoading,
  setTopTeams,
  updateTopTeams,
  setProductivity,
  updateProductivityLoading,
  updateTaskCount,
  setDateRange
} = dashboardSlice.actions
export default dashboardSlice.reducer
export const getTopProjects = createAsyncThunk(
  "adminDashboard/getTopProjects",
  async (params, { dispatch }) => {
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    dispatch(getTopProjectLoading(true))
    const response = await httpService(
      "GET",
      "/Dashboard/topprojects",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(getTopProject(response?.data?.data))
      dispatch(getTopProjectLoading(false))
    } else {
      dispatch(getTopProject([]))
      dispatch(getTopProjectLoading(false))
    }
  }
)
export const getTopTasks = createAsyncThunk(
  "adminDashboard/getTopTasks",
  async (params, { dispatch }) => {
    dispatch(getTopTaskLoading(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    const response = await httpService("GET", "/Dashboard/toptasks", "", param)
    if (response.status === 200) {
      dispatch(getTopTask(response?.data?.data))
      dispatch(getTopTaskLoading(false))
    } else {
      dispatch(getTopTask([]))
      dispatch(getTopTaskLoading(false))
    }
  }
)
export const getAllTasksCount = createAsyncThunk(
  "adminDashboard/alltaskscount",
  async (param, { dispatch }) => {
    dispatch(getCountLoading(true))
    const response = await httpService(
      "GET",
      "/Dashboard/alltaskscount",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(setAllTaskCount(response?.data?.data))
      dispatch(setDateRange(param))
      dispatch(getCountLoading(false))
    } else {
      dispatch(setAllTaskCount({}))
      dispatch(getCountLoading(false))
    }
  }
)
export const updateAllTasksCount = createAsyncThunk(
  "adminDashboard/alltaskscount",
  async (param, { dispatch, getState }) => {
    const TasksAllCount = getState()?.AdminDashboard?.adminTasksAllCount
    const setDateRange = getState()?.AdminDashboard?.dateRange
    const fromDate = moment(setDateRange?.fromdate)
    const toDate = moment(setDateRange?.todate)
    const plannedstartDate = moment(param?.plannedstartDate)

    if (
      plannedstartDate.isSameOrAfter(fromDate) &&
      plannedstartDate.isSameOrBefore(toDate)
    ) {
      dispatch(
        setAllTaskCount({
          ...TasksAllCount,
          [param?.statustype]: TasksAllCount[param?.statustype] + 1,
          totalTasks: TasksAllCount.totalTasks + 1
        })
      )
    }
  }
)
export const getTopEmployees = createAsyncThunk(
  "adminDashboard/topemployees",
  async (param, { dispatch }) => {
    const params = {
      fromdate: param?.start,
      todate: param?.end
    }
    dispatch(updateTopEmployeesLoader(true))
    const response = await httpService(
      "GET",
      "/Dashboard/topemployees",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(setTopEmployees(response?.data?.data))
      dispatch(updateTopEmployeesLoader(false))
    } else {
      dispatch(setTopEmployees({}))
      dispatch(updateTopEmployeesLoader(false))
    }
  }
)

export const getBillings = createAsyncThunk(
  "adminDashboard/getTopBillings",
  async (params, { dispatch }) => {
    dispatch(getTopBillingLoading(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end
    }
    const response = await httpService(
      "GET",
      "/OrgSetting/GetOrganizationInvoices",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(getTopBillings(response?.data))
      dispatch(getTopBillingLoading(false))
    } else {
      dispatch(getTopBillings([]))
      dispatch(getTopBillingLoading(false))
    }
  }
)

export const getTopTeams = createAsyncThunk(
  "adminDashboard/topteams",
  async (params, { dispatch }) => {
    dispatch(updateTopTeams(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end,
      count: 10
    }
    const response = await httpService("GET", "/Dashboard/topteams", "", param)
    if (response.status === 200) {
      dispatch(setTopTeams(response?.data?.data))
      dispatch(updateTopTeams(false))
    } else {
      dispatch(setTopTeams([]))
      dispatch(updateTopTeams(false))
    }
  }
)

export const getProductivity = createAsyncThunk(
  "adminDashboard/topteams",
  async (params, { dispatch }) => {
    dispatch(updateProductivityLoading(true))
    const param = {
      fromDate: params?.start,
      toDate: params?.end,
      count: 10
    }
    const response = await httpService(
      "GET",
      "/Dashboard/EmployeesProductivityMeter",
      "",
      param
    )
    if (response.status === 200) {
      dispatch(setProductivity(response?.data?.data))
      dispatch(updateProductivityLoading(false))
    } else {
      dispatch(setProductivity([]))
      dispatch(updateProductivityLoading(false))
    }
  }
)
