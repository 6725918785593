// ** Logo
import { useSkin } from "@hooks/useSkin"

const logo = `${process.env.REACT_APP_ASSETS_URL}/logo/logo.png`
const darklogo = `${process.env.REACT_APP_ASSETS_URL}/logo/darklogo.png`

const SpinnerComponent = () => {
  const { skin } = useSkin()

  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={skin !== "dark" ? logo : darklogo}
        alt="logo"
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
