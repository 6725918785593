// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"

export const screenshotSlice = createSlice({
  name: "screenshots",
  initialState: {
    screenshotsArr: [],
    screenShotLoadingStatus: false,
    counts: {},
    alreadyRequestSent: false,
    filteredTasks: [],
    filteredTasksLoader: false
  },
  reducers: {
    filteredTasksLoader: (state, action) => {
      state.filteredTasksLoader = action.payload
    },
    filteredTasks: (state, action) => {
      state.filteredTasks = action.payload
    },
    pageCounts: (state, action) => {
      state.counts = action.payload
    },
    allScreenShots: (state, action) => {
      state.screenshotsArr = action.payload
    },
    loadingStatus: (state, action) => {
      state.screenShotLoadingStatus = action.payload
    },
    handleAlreadyRequested: (state, action) => {
      state.alreadyRequestSent = action.payload
    }
  }
})
export const {
  allScreenShots,
  filteredTasks,
  filteredTasksLoader,
  handleAlreadyRequested,
  loadingStatus,
  pageCounts
} = screenshotSlice.actions

export const getFilteredTasks = createAsyncThunk(
  "screenshots/getFilteredTasks",
  async (params, { dispatch }) => {
    dispatch(filteredTasksLoader(true))
    const response = await httpService(
      "GET",
      "Screenshots/gettaskfilter",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(filteredTasksLoader(false))
      dispatch(filteredTasks(response.data.tasksDDL))
    } else {
      dispatch(filteredTasks([]))
      dispatch(filteredTasksLoader(false))
    }
  }
)
export const getScreenshots = createAsyncThunk(
  "screenshots/getScreenshots",
  async (params, { dispatch, getState }) => {
    const previousData = getState().screenshots.screenshotsArr
    params?.Page === 1 ? dispatch(loadingStatus(true)) : ""
    dispatch(handleAlreadyRequested(true))
    const response = await httpService(
      "GET",
      "/Screenshots/screenshotslist",
      "",
      params
    )
    if (response.status === 200) {
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }
      params?.Page === 1
        ? dispatch(allScreenShots(response?.data?.data))
        : dispatch(allScreenShots([...previousData, ...response?.data?.data]))
      dispatch(pageCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(handleAlreadyRequested(false))
      return true
    } else {
      dispatch(loadingStatus(false))
      dispatch(handleAlreadyRequested(false))
      return false
    }
  }
)
export default screenshotSlice.reducer
