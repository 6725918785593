// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { handleTagModal } from "@views/pages/MyTasks/components/MainModals/store"
// ** Service Imports
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const tagstablesSlice = createSlice({
  name: "tagstables",
  initialState: {
    data: [],
    tagData: {},
    isEdit: false,
    loader: false,
    isLoading: false
  },
  reducers: {
    editMode: (state, action) => {
      state.isEdit = action.payload
    },
    tagData: (state, action) => {
      state.tagData = action.payload
    },
    getTagData: (state, action) => {
      state.data = action.payload
    },
    loadingTags: (state, action) => {
      state.isLoading = action.payload
    },
    loadingStatus: (state, action) => {
      state.loader = action.payload
    }
  }
})

export default tagstablesSlice.reducer
export const { getTagData, loadingStatus, loadingTags, editMode, tagData } =
  tagstablesSlice.actions
export const getData = createAsyncThunk(
  "tagstables/getData",
  async (params, { dispatch }) => {
    dispatch(loadingTags(true))
    const response = await httpService("GET", "/Tags/tagslist", "", params)
    if (response.status === 200) {
      dispatch(getTagData(response?.data?.data))
      dispatch(loadingTags(false))
    } else {
      dispatch(loadingTags(false))
      dispatch(getTagData([]))
    }
  }
)
export const singleTagData = createAsyncThunk(
  "tagstables/singleTagData",
  async (params, { dispatch }) => {
    const response = await httpService("GET", `/Tags/edit?id=${params}`, "", "")
    if (response.status === 200) {
      dispatch(tagData(response?.data?.data))
      dispatch(editMode(true))
      dispatch(handleTagModal(true))
    } else {
      Toaster("error", "This Tag cannot be edited", "Failed")
    }
  }
)

//
export const updateTag = createAsyncThunk(
  "tagstables/updateTag",
  async (params, { dispatch }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", `/Tags/edit`, params, "")
    if (response.status === 200) {
      dispatch(getTagData(response?.data?.data))
      dispatch(loadingStatus(false))
      dispatch(handleTagModal(false))
      dispatch(editMode(false))
      Toaster("success", "Tag Updated Successfully", "Success")
    } else {
      Toaster("error", "Tag not Updated", "Failed")
    }
  }
)
//
export const postData = createAsyncThunk(
  "tagstables/postData",
  async (param, { dispatch, getState }) => {
    dispatch(loadingStatus(true))
    const response = await httpService("POST", "/Tags/create", param, "")
    if (response?.status === 200) {
      const addData = [...getState()?.TagsData?.data, ...response?.data?.data]
      dispatch(getTagData(addData))
      dispatch(loadingStatus(false))
      Toaster("success", "Tag Added Successfully", "Success")
    } else {
      dispatch(loadingStatus(false))
      Toaster("error", response?.data?.response?.data?.message, "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const postDelete = createAsyncThunk(
  "tagstables/postDelete",
  async (param, { dispatch, getState }) => {
    const prevData = [...getState()?.TagsData?.data]
    const response = await httpService(
      "POST",
      `/Tags/delete?id=${param}`,
      "",
      ""
    )
    if (response?.status === 200) {
      const index = prevData?.findIndex(tags => tags?.tagId === param)
      index > -1 ? prevData.splice(index, 1) : ""
      dispatch(getTagData(prevData))
      Toaster("success", "Tag Deleted Successfully", "Success")
    } else {
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
