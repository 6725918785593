// ** third-party imports
import { Coffee } from "react-feather"
import { toast } from "react-toastify"
// ** Custom Components
import Avatar from "@components/avatar"

const ToastContent = ({ type, title, message }) => {
  return (
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar
            size="sm"
            color={`${type === "error" ? "danger" : type}`}
            icon={<Coffee size={12} />}
          />
          <h6 className="toast-title fw-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>{message}</span>
      </div>
    </>
  )
}

const Toaster = (type, message, title, position) => {
  const options = {
    icon: false,
    hideProgressBar: true,
    autoClose: type === "error" ? false : 2000,
    position: position ?? "top-right"
  }
  return toast[type](
    <ToastContent type={type} message={message} title={title} />,
    options
  )
}

export default Toaster
