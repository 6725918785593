import { handleLogout } from "@store/authentication"
import { handleAttendance } from "../redux/navbar"
import { store } from "../redux/store"
import { reOrderTasks, taskBoardTasks } from "../views/pages/MyTasks/store"
import { attendanceStart } from "./store"

export const taskHandler = (dispatch, clickedTask) => {
  const { myTasks } = store.getState()
  const { tasks } = myTasks
  const task = tasks?.map(t => {
    return t.taskId === clickedTask?.taskId ? { ...t, ...clickedTask } : t
  })
  dispatch(reOrderTasks(task))
  dispatch(taskBoardTasks(null))
}
export const attendanceHandler = (dispatch, attendance) => {
  const { clockIn, clockOut } = attendance
  if (clockIn !== null && clockOut === null) {
    dispatch(attendanceStart(true))
  } else if (clockOut !== null) {
    dispatch(attendanceStart(false))
  }
  dispatch(handleAttendance(attendance))
}

export const logoutHandler = async (dispatch, history, connection) => {
  dispatch(handleAttendance({}))
  dispatch(handleLogout())
  connection.stop()
  history.push("/login")
}
