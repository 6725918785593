// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const taskViewSlice = createSlice({
  name: "taskView",
  initialState: {
    taskView: "List",
    searchByKey: "plannedEndDate",
    groupByKey: "status"
  },
  reducers: {
    handleTaskView: (state, action) => {
      state.taskView = action.payload
    },
    handleSearchByKey: (state, action) => {
      state.searchByKey = action.payload
    },
    handleGroupByKey: (state, action) => {
      state.groupByKey = action.payload
    }
  }
})
export const { handleTaskView, handleGroupByKey, handleSearchByKey } =
  taskViewSlice.actions
export default taskViewSlice.reducer
