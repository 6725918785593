import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpService } from "@services/api"
import Toaster from "@modules/Toaster"

export const TicketData = createSlice({
  name: "Ticket",
  initialState: {
    ticketList: [],
    ticketsLoading: false,
    ticket: {},
    files: [],
    pageCount: {},
    ticketLoading: false,
    threads: [],
    addSingleTicket: [],
    loader: false,
    orgsUsers: [],
    fileUploadingStatus: false,
    orgsLoader: false,
    threadsLoading: false,
    singleThread: {},
    threadLoader: false,
    loadMore: false,
    ticketPrioirtyLoader: false,
    ticketStatusLoader: false
  },
  reducers: {
    ticketList: (state, action) => {
      state.ticketList = action.payload
    },
    threads: (state, action) => {
      state.threads = action.payload
    },
    reOrderTickets: (state, action) => {
      state.ticketList = action.payload
    },
    uploadFiles: (state, action) => {
      state.files = action.payload
    },
    handlePageCount: (state, action) => {
      state.pageCount = action.payload
    },
    fileUploading: (state, action) => {
      state.fileUploadingStatus = action.payload
    },
    setThreadsLoading: (state, action) => {
      state.threadsLoading = action.payload
    },
    setTicketsLoading: (state, action) => {
      state.ticketsLoading = action.payload
    },
    ticket: (state, action) => {
      state.ticket = action.payload
    },
    setTicketLoading: (state, action) => {
      state.ticketLoading = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    ticketPrioirtyLoader: (state, action) => {
      state.ticketPrioirtyLoader = action.payload
    },
    addSingleTicket: (state, action) => {
      state.addSingleTicket = action.payload
    },
    orgsUsers: (state, action) => {
      state.orgsUsers = action.payload
    },
    orgsLoader: (state, action) => {
      state.orgsLoader = action.payload
    },
    singleThread: (state, action) => {
      state.singleThread = action.payload
    },
    threadLoader: (state, action) => {
      state.threadLoader = action.payload
    },
    loadMore: (state, action) => {
      state.loadMore = action.payload
    },
    ticketStatusLoader: (state, action) => {
      state.ticketStatusLoader = action.payload
    }
  }
})
export const {
  ticketList,
  setTicketsLoading,
  setTicketLoading,
  ticket,
  loadMore,
  setThreadsLoading,
  addSingleTicket,
  setLoader,
  threads,
  orgsUsers,
  threadLoader,
  singleThread,
  uploadFiles,
  fileUploading,
  orgsLoader,
  reOrderTickets,
  ticketStatusLoader,
  handlePageCount,
  ticketPrioirtyLoader
} = TicketData.actions

export const getTickets = createAsyncThunk(
  "Ticket/getTicketsList",
  async (params, { dispatch, getState }) => {
    if (params?.PageNo) {
      dispatch(loadMore(true))
    } else {
      dispatch(setTicketsLoading(true))
    }

    const response = await httpService("GET", "Tickets/TicketsList", "", params)

    if (response.status === 200) {
      if (params?.PageNo) {
        dispatch(loadMore(false))
        const previousData = getState()?.Ticket?.ticketList
        const previousDataCopy = [...previousData]
        dispatch(reOrderTickets([...previousDataCopy, ...response?.data?.data]))
      } else {
        dispatch(setTicketsLoading(false))
        dispatch(ticketList([...response?.data.data]))
      }
      const pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }
      dispatch(handlePageCount(pageCount))
    } else {
      dispatch(handlePageCount({}))
      dispatch(loadMore(false))
      dispatch(setTicketsLoading(false))
      dispatch(ticketList([]))
    }
    return response?.status === 200 ? true : false
  }
)
export const getSingleTicket = createAsyncThunk(
  "Ticket/getSingleTicket",
  async (params, { dispatch }) => {
    dispatch(setTicketLoading(true))

    const response = await httpService(
      "GET",
      `Tickets/GetSingleTicketDetails/${params}`,
      "",
      ""
    )

    if (response.status === 200) {
      dispatch(setTicketLoading(false))

      dispatch(ticket(response?.data?.data))
    } else {
      dispatch(setTicketLoading(false))
      dispatch(ticket({}))
    }
  }
)
export const addTicket = createAsyncThunk(
  "Ticket/addTicket",
  async (params, { dispatch, getState }) => {
    dispatch(setTicketLoading(true))
    const previousData = getState()?.Ticket?.ticketList
    const previousDataCopy = [...previousData]
    const response = await httpService(
      "POST",
      "Tickets/AddSingleTicket",
      params,
      ""
    )

    if (response.status === 200) {
      dispatch(reOrderTickets([response?.data?.data, ...previousDataCopy]))
      Toaster("success", "Ticket added successfully", "Success")
      dispatch(setTicketLoading(false))
    } else {
      dispatch(setTicketLoading(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
    return response?.status === 200 ? true : false
  }
)
export const getTenantUsers = createAsyncThunk(
  "Tickets/getTenantUsers",
  async (params, { dispatch }) => {
    dispatch(orgsLoader(true))
    const response = await httpService(
      "GET",
      `DropDowns/GetOrganizationUsers/${params}`,
      "",
      ""
    )
    if (response.status === 200) {
      dispatch(orgsUsers(response?.data?.data))
      dispatch(orgsLoader(false))
    } else {
      dispatch(orgsUsers([]))
      dispatch(orgsLoader(false))
    }
  }
)
export const getTicketThreads = createAsyncThunk(
  "Ticket/getTicketThreads",
  async (params, { dispatch }) => {
    dispatch(threads([]))
    dispatch(setThreadsLoading(true))
    const response = await httpService(
      "GET",
      `Tickets/GetTicketThreads/${params}`,
      "",
      ""
    )

    if (response.status === 200) {
      dispatch(threads(response?.data?.data))
      dispatch(setThreadsLoading(false))
    } else {
      dispatch(setThreadsLoading(false))
      dispatch(threads([]))
    }
  }
)
export const getAddSingleTicket = createAsyncThunk(
  "Ticket/getAddSinleTicket",
  async (params, { dispatch }) => {
    dispatch(setLoader(true))
    const response = await httpService(
      "GET",
      `Tickets/AddSingleTicket/`,
      "",
      ""
    )

    if (response.status === 200) {
      dispatch(addSingleTicket(response?.data?.data))
      dispatch(setLoader(false))
    } else {
      dispatch(setLoader(false))
      dispatch(threads([]))
    }
  }
)
export const addTicketThread = createAsyncThunk(
  "Ticket/addTicketThread",
  async (params, { dispatch, getState }) => {
    const prevThreads = [...getState()?.Ticket.threads]
    dispatch(threadLoader(true))
    const response = await httpService(
      "POST",
      `Tickets/AddSingleTicketThread/`,
      params,
      ""
    )
    const finalData =
      prevThreads?.length > 0
        ? [response?.data?.data, ...prevThreads]
        : [response?.data?.data]
    if (response.status === 200) {
      Toaster("success", "Thread added successfully", "Success")
      dispatch(threads(finalData))
      dispatch(threadLoader(false))
    } else {
      Toaster("error", "Something went wrong please try again.", "Failed")
      dispatch(threadLoader(false))
      dispatch(threads([]))
    }
  }
)

export const UploadFile = createAsyncThunk(
  "Ticket/UploadFile",
  async (body, { dispatch, getState }) => {
    const response = await httpService(
      "POST",
      "Templates/uploadfile",
      body?.formData,
      ""
    )
    if (response?.status === 200) {
      const previousFiles = getState()?.Ticket?.files
      const newFile = {
        attachmentURL: response?.data?.filePath,
        attachmentName: body.name
      }
      const previousFilesCopy = [...previousFiles, newFile]
      dispatch(uploadFiles(previousFilesCopy))
      if (body?.totalHits === body?.indexNo) {
        Toaster("success", "Files uploaded successfully", "Success")
      }
      return {
        file: response.data,
        status: true
      }
    } else {
      dispatch(uploadFiles([]))
      if (body?.totalHits === body?.indexNo) {
        Toaster("error", "Something went wrong please try again.", "Failed")
      }
      return {
        file: null,
        status: false
      }
    }
  }
)
export const updateTicketPriority = createAsyncThunk(
  "Ticket/updateTicketPriority",
  async (params, { dispatch, getState }) => {
    dispatch(ticketPrioirtyLoader(true))
    const response = await httpService(
      "POST",
      "Tickets/UpdateTicketPriority",
      params,
      ""
    )
    if (response.status === 200) {
      const prevTicketList = [...getState()?.Ticket.ticketList]
      const singleTicketDetails = { ...getState().Ticket.ticket }
      singleTicketDetails.priority = response?.data?.data?.toPriority
      const updatedTicketList = prevTicketList.map(item => {
        if (item.recno === singleTicketDetails.recno) {
          return { ...item, priority: response?.data?.data?.toPriority }
        }
        return item
      })
      dispatch(ticket(singleTicketDetails))
      dispatch(ticketList(updatedTicketList))
      dispatch(ticketPrioirtyLoader(false))
      Toaster("success", "Ticket priority updated successfully", "Success")
    } else {
      dispatch(ticketPrioirtyLoader(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
export const updateTicketStatus = createAsyncThunk(
  "Ticket/updateTicketStatus",
  async (params, { dispatch, getState }) => {
    dispatch(ticketStatusLoader(true))
    const response = await httpService(
      "POST",
      "Tickets/UpdateTicketStatus",
      params,
      ""
    )
    if (response.status === 200) {
      const { toStatusId, toStatusDescription } = response?.data.data
      const prevTickets = [...getState()?.Ticket?.ticketList]
      const singleTicketDetails = { ...getState().Ticket.ticket }
      singleTicketDetails.status = toStatusId
      const updatedTicketList = prevTickets.map(item => {
        if (item.recno === singleTicketDetails.recno) {
          return {
            ...item,
            status: toStatusId,
            statusDescription: toStatusDescription
          }
        }
        return item
      })
      dispatch(ticket(singleTicketDetails))
      dispatch(ticketList(updatedTicketList))
      dispatch(ticketStatusLoader(false))
      Toaster("success", "Ticket priority updated successfully", "Success")
    } else {
      dispatch(ticketStatusLoader(false))
      Toaster("error", "Something went wrong please try again.", "Failed")
    }
  }
)
export default TicketData.reducer
