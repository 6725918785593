export const addTaskObj = {
  taskId: 0,
  projectId: 0,
  tenantId: 0,
  taskName: "",
  description: "",
  tags: "",
  isSelfCreated: true,
  label: 0,
  isClosed: true,
  startDate: null,
  endDate: null,
  taskHours: 0,
  plannedHours: 0,
  state: 0,
  area: 0,
  taskType: 0,
  score: 0,
  plannedStartDate: null,
  plannedEndDate: null,
  status: 0,
  priority: 0,
  checkedByEmpId: 0,
  closedByEmpId: 0,
  createdBy: 0,
  createdDate: null,
  editedBy: 0,
  editedDate: null,
  sourceTpe: "",
  sourceNo: 0,
  site: "",
  userId: 0,
  comments: "",
  plannedDuration: "",
  verificationDate: null,
  verifiedById: 0,
  orgEKey: "",
  orgEToken: "",
  remindMe: 0,
  clockIn: null,
  clockOut: null,
  files: [],
  strPriority: "",
  userIds: [],

  vMRecurrence: {
    recMonthlycycleday: 0,
    recMonthcylyleperiod: 0,
    recMonthlyPeriodType: "",
    recMonthlystartdate: "",
    recMonthlyenddate: "",
    recDailydays: 0,
    recDailyweekday: [],
    recstartdate: "",
    recenddate: "",
    reccheckbox: "",
    rectype: ""
  },

  vmProjectTasksChecklists: [
    {
      checklistId: 0,
      checkListTemplateId: 0,
      taskId: 0,
      taskName: "",
      title: "",
      dueDate: null,
      isCompleted: true,
      completionDate: null,
      createdBy: 0,
      createdDate: null,
      editedBy: 0,
      editedDate: null,
      tenantId: 0
    }
  ]
}

export const updateTaskObj = {
  type: "",
  taskId: 0,
  deviceType: "",
  deviceId: "",
  latitude: "",
  longitude: ""
}

export const updateTaskQuicklyObj = {
  taskIds: [],
  statusId: 0,
  updateType: "status"
}
