// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    showFilter: false,
    isOpenFilter: false,
    filterForm: null
  },
  reducers: {
    handleShowFilter: (state, action) => {
      state.showFilter = action.payload
    },
    handleIsOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload
    },
    handleFilterForm: (state, action) => {
      state.filterForm = action.payload
    }
  }
})
export const { handleShowFilter, handleFilterForm, handleIsOpenFilter } =
  filterSlice.actions
export default filterSlice.reducer
