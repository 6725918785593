// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
// ** Dependencies Imports
export const TeamAttendanceData = createSlice({
  name: "TeamAttendanceData",
  initialState: {
    teamAttendanceFiltersDDL: {},
    loadingStatus: false,
    cardsData: [],
    cardsDataStatus: false,
    teamAttendanceData: [],
    teamAttendanceAlreadyRequestSent: false,
    dataCounts: {},
    teamAttendanceLoadMoreStatus: false
  },
  reducers: {
    cardsData: (state, action) => {
      state.cardsData = action.payload
    },
    cardsDataStatus: (state, action) => {
      state.cardsDataStatus = action.payload
    },
    attendancetable: (state, action) => {
      state.teamAttendanceData = action.payload
    },
    loadingStatus: (state, action) => {
      state.loadingStatus = action.payload
    },
    teamAttendanceFilters: (state, action) => {
      state.teamAttendanceFiltersDDL = action.payload
    },
    dataCounts: (state, action) => {
      state.dataCounts = action.payload
    },
    loadingStatusLoadmore: (state, action) => {
      state.teamAttendanceLoadMoreStatus = action.payload
    },
    teamAttendanceLogAlreadyRequestSent: (state, action) => {
      state.teamAttendanceAlreadyRequestSent = action.payload
    }
  }
})

export const {
  cardsData,
  cardsDataStatus,
  attendancetable,
  loadingStatus,
  teamAttendanceFilters,
  dataCounts,
  teamAttendanceLogAlreadyRequestSent,
  loadingStatusLoadmore
} = TeamAttendanceData.actions
export const getTeamAttendanceData = createAsyncThunk(
  "TeamAttendanceData/TeamAttendanceData",
  async (params, { dispatch, getState }) => {
    dispatch(teamAttendanceLogAlreadyRequestSent(true))
    const previousData = getState()?.TeamAttendanceData?.teamAttendanceData
    params.page === 1
      ? dispatch(loadingStatus(true))
      : dispatch(loadingStatusLoadmore(true))
    const response = await httpService(
      "GET",
      `/TeamAttendance/getteamattendancesheet`,
      "",
      params
    )
    let pageCount
    if (response.status === 200) {
      pageCount = {
        totalItemCount: response?.data?.totalItemCount,
        pageCount: response?.data?.pageCount,
        pageNumber: response?.data?.pageNumber,
        hasNextPage: response?.data?.hasNextPage,
        hasPreviousPage: response?.data?.hasPreviousPage
      }

      response?.data?.pageNumber === 1
        ? dispatch(attendancetable(response?.data?.data))
        : dispatch(attendancetable([...previousData, ...response?.data?.data]))
      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(teamAttendanceLogAlreadyRequestSent(false))
      dispatch(loadingStatusLoadmore(false))
      return true
    } else {
      dispatch(attendancetable([]))
      dispatch(dataCounts(pageCount))
      dispatch(loadingStatus(false))
      dispatch(teamAttendanceLogAlreadyRequestSent(false))
      dispatch(loadingStatusLoadmore(false))
      return false
    }
  }
)
export const getCardsData = createAsyncThunk(
  "TeamAttendanceCardsData/TeamAttendanceData",
  async (params, { dispatch }) => {
    dispatch(cardsDataStatus(true))
    const response = await httpService(
      "GET",
      "TeamAttendance/getteamattendancesheetcount",
      "",
      params
    )
    if (response.status === 200) {
      dispatch(cardsData(response?.data))
      dispatch(cardsDataStatus(false))
    } else {
      dispatch(cardsData([]))
      dispatch(cardsDataStatus(false))
    }
  }
)
export const getTeamAttendanceFilters = createAsyncThunk(
  "TeamAttendanceData/getTeamAttendanceFilterData",
  async (params, { dispatch }) => {
    const response = await httpService(
      "GET",
      "/TeamAttendance/getfilters",
      "",
      params
    )
    if (response?.status === 200) {
      dispatch(teamAttendanceFilters(response?.data))
    } else {
      dispatch(teamAttendanceFilters({}))
    }
  }
)
export default TeamAttendanceData.reducer
