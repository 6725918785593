// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Service Imports
import { httpService } from "@services/api"
import { formatDate } from "@dates"
// ** Dependencies Imports
export const ActivityCalenderData = createSlice({
  name: "ActivityCalenderData",
  initialState: {
    data: [],
    calendarLoading: false,
    calenderdata: {}
  },
  reducers: {
    calenderdata: (state, action) => {
      state.calenderdata = action.payload
    },
    updatedata: (state, action) => {
      state.data = action.payload
    },
    calendarLoader: (state, action) => {
      state.calendarLoading = action.payload
    }
  }
})

export const { calenderdata, calendarLoader, updatedata } =
  ActivityCalenderData.actions

export const getData = createAsyncThunk(
  "ActivityCalenderData/ActivityCalenderData",

  async (params, { dispatch, getState }) => {
    dispatch(calendarLoader(true))
    const preCalData = getState()?.ActivityCalenderData?.calenderdata
    const preData = getState()?.ActivityCalenderData?.data
    const response = await httpService(
      "GET",
      `/ActivityLog/activitycalendarlist`,
      "",
      params
    )
    if (response.status === 200) {
      const data = { ...preCalData }
      data[`${formatDate(params.FromDueDate, "YYYY-MM")}`] = response.data.data
      dispatch(calenderdata(data))
      dispatch(updatedata([...preData, ...response.data.data]))
      dispatch(calendarLoader(false))
    } else {
      dispatch(calenderdata([]))
      dispatch(calendarLoader(false))
    }
  }
)

export default ActivityCalenderData.reducer
