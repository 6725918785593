import _ from "lodash"
const priorityDDL = [
  {
    value: "High",
    text: "High"
  },
  {
    value: "Medium",
    text: "Medium"
  },
  {
    value: "Low",
    text: "Low"
  }
]

export const convertToLower = string => {
  if (typeof string !== "undefined") {
    return string.replace(/\s+/g, "-").toLowerCase()
  }
  return string
}

const getType = (value, array, filter) => {
  value = filter === "status" ? +value : value.toString()
  const type = _.find(array, { value })
  if (typeof type === "undefined") return "Others"
  return type.text
}

const getTaskCount = (allCounts, filter, id) => {
  let keys = Object.keys(allCounts)
  const values = Object.values(allCounts)
  let index
  if (filter === "status") {
    index = keys.findIndex(item => convertToLower(item).includes(id))
    index = index === -1 ? keys.findIndex(item => item === "completed") : index
  } else if (filter === "strPriority") {
    keys = keys.map(item => item.replace("total", ""))
    index = keys.findIndex(item => item === id)
  } else {
    index = keys.findIndex(item => convertToLower(item) === id)
  }
  return values[index]
}

const groupDataByFilter = (array, filter, DDL, DdlArray, data) => {
  const taskById = []
  const tasksGroup = []
  DdlArray?.map((group, index) => {
    tasksGroup[index] = {
      id: filter === "strPriority" ? group.text : convertToLower(group.text),
      title: group.text,
      taskIds: [],
      apiKey: filter === "strPriority" ? group.value : +group.value
    }
  })
  // map data to ids index
  array?.map((task, index) => {
    taskById[index] = task
  })
  // create group with task ids
  _.chain(array)
    .groupBy(filter)
    .map((value, key) => {
      const keyString = getType(key, DdlArray, filter)
      const checkIndex = keyString === "Others" ? "0" : key
      let indexKey = tasksGroup.findIndex(item => {
        return filter === "strPriority"
          ? item.apiKey === checkIndex
          : +item.apiKey === +checkIndex
      })
      if (indexKey === -1) {
        indexKey = tasksGroup.length - 1
      }
      const count = getTaskCount(
        data.taskCount,
        filter,
        filter === "strPriority" ? keyString : convertToLower(keyString)
      )
      tasksGroup[indexKey] = {
        id: filter === "strPriority" ? keyString : convertToLower(keyString),
        title: keyString,
        taskIds: value.map(task => task.taskId),
        apiKey: filter === "strPriority" ? key : +key,
        count
      }
    })
    .value()
  return [taskById, tasksGroup]
}

export const parseData = (data, filter) => {
  const finalData = {
    tasks: {},
    groups: {},
    groupOrder: {}
  }
  const groupOrder = []
  const tasks = _.uniqBy(data?.tasks, "taskId")
  const statusesDDL = data?.teamTaskFiltersDDL?.tasksStatusDDL
  const tasksGroupDDL = data?.tasksGroupDDL
  const typeDDL = [...tasksGroupDDL, { text: "Others", value: "0" }]
  const projectDDL = data?.addTaskDDL?.projectsDDL
  const DDL =
    filter === "status"
      ? statusesDDL
      : filter === "taskType"
      ? typeDDL
      : filter === "projectId"
      ? projectDDL
      : priorityDDL
  // prepare data to pass to task board
  DDL?.map(status => {
    groupOrder.push(status?.text)
  })
  const groupData = groupDataByFilter(tasks, filter, groupOrder, DDL, data)
  finalData.tasks = groupData[0]
  finalData.groups = groupData[1]
  finalData.groupOrder = groupOrder
  return finalData
}
